<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="6" sm="12">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="org_id"
              >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="orgList"
                  id="org_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="training_type_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_type')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.training_type_id"
                  :options="trainingTypeList"
                  id="training_type_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_title_id"
                >
                <template v-slot:label>
                  {{$t('elearning_config.training_title')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.training_title_id"
                  :options="trainingTitleList"
                  id="training_title_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
          </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="course-module-form" class="btn btn-add">
              <i class="fas fa-plus"></i> {{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row>
                    <b-col sm="12">
                      <div class="quick-filter-wrapper">
                        <div class="quick-filter-left">
                        <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                            <b-dropdown-form>
                            <div
                                class="form-group"
                                v-for="(field, index) in labelData"
                                :key="index"
                                >
                                <b-form-checkbox
                                    :id="'checkbox-' + field.label"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label"
                                    value=1
                                    unchecked-value=0
                                >
                                    {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                </b-form-checkbox>
                            </div>
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                        <div class="quick-filter-right">
                        <b-form-group
                            :label="$t('menu.perpage')"
                            label-for="per-page-select"
                        >
                            <b-form-select
                            id="per-page-select"
                            v-model="search.limit"
                            :options="pageOptions"
                            size="sm"
                            ></b-form-select>
                        </b-form-group>
                        </div>
                    </div>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                      <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                        <template v-slot:cell(serial)="data">
                          {{ $n(data.item.serial + pagination.slOffset) }}
                        </template>
                        <template v-slot:cell(type_name)="data">
                          <span class="capitalize">{{ data.item.type_name }}</span>
                        </template>
                        <template v-slot:cell(status)="data">
                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                        </template>
                        <template v-slot:cell(action)="data">
                            <!-- <b-button variant="btn btn-sm btn-success" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button> -->
                            <router-link class="action-btn edit" v-if="data.item.status == 1" variant=" iq-bg-success mr-1" title="$t('globalTrans.edit')" :to="`/training-e-learning-service/tpm/course-module-form?training_title_id=${data.item.training_title_id}`"><i class="ri-ball-pen-fill m-0"></i></router-link>
                            <b-button class="action-btn active view" variant=" iq-bg-success mr-1" :title="$t('globalTrans.view')"  v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-eye-fill"></i></b-button>
                        </template>
                      </b-table>
                    </slot>
                    <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                      <b-pagination
                        v-model="pagination.currentPage"
                        :perPage="search.limit"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_module') + ' ' + $t('globalTrans.details')"  hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
              {{ $t('elearning_tpm.course_module') }}
              <b-button variant="success" @click="pdfExport" class="mr-2 float-right">
                <i class="fas fa-print"></i> {{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseModuleList, courseModuleToggleStatus, courseModuleFinalSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        sortBy: '',
        sortDirection: '',
        sortDesc: '',
        search: {
          circular_memo_no: '',
          org_id: 0,
          training_type_id: 0,
          training_category_id: 0,
          training_title_id: 0,
          fiscal_year_id: 0,
          limit: 20
        },
        trainingTypeList: [],
        trainingTitleList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '25%' } },
          { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '15%' } }
        ]
      }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'organization_name_bn' },
            { key: 'training_type_bn' },
            { key: 'training_title_bn' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'organization_name' },
            { key: 'training_type' },
            { key: 'training_title' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        // org_id: this.$store.state.dataFilters.orgId,
        // org_id: 9,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      this.loadData()
    },
    watch: {
      'search.org_id': function (newValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      finalSave (item) {
        this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseModuleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getTypeList (typeId) {
        const trainingTypeList = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (typeId) {
          return trainingTypeList.filter(item => item.org_id === typeId)
        }
        return trainingTypeList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, courseModuleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, courseModuleList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const organizationObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const organizationData = {}
          if (typeof organizationObj !== 'undefined') {
            organizationData.organization_name = organizationObj.text_en
            organizationData.organization_name_bn = organizationObj.text_bn
          } else {
            organizationData.organization_name = ''
            organizationData.organization_name_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, { serial: index }, item, trainingTitleData, trainingTypeData, organizationData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
